<script>
import { administrativeGender, religiousAffiliation, race, ethnicityDetail, pronouns, languages, genderIdentity, maritalStatus, sexualOrientation } from '@/common/api/terminology.api';
import { Address, createFhirResource, ContactPoint, PatientCommunication, traits, types as FHIRWorksTypes } from '@/fhirworks';

import dataProvider from '@/components/DataProvider.js';
import BnDatePicker from '@/components/BnDatePicker.vue';

import { isPatient, sortByList } from '@/common/core.js';

import timezoneApi from '@/common/api/timezone.api.js';
import { getGoogleTimeZoneInfo } from '@/common/api/googleTimeZone.api.js';

import { minLength, required, requiredIf } from '@vuelidate/validators';
import { usePatientAccess } from '@/composables/usePatientAccess';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import { $httpTerminology } from '@/common/api/terminology.api.js';
import BnImageUploader from '@/components/BnImageUploader.vue';
import BnListBox from '@/components/BnListBox.vue';
import BnDialog from '@/components/BnDialog.vue';
import BnContactPointEdit from '@/components/contacts/BnContactPointEdit.vue';
import BnContactAddress from '@/components/contacts/BnContactAddress.vue';
import BnListQualifications from '@/components/lists/BnListQualifications.vue';
import BnListIdentifiers from '@/components/lists/BnListIdentifiers.vue';
import BnInfo from '@/components/BnInfo.vue';
import BnRelated from '@/components/contacts/BnRelated.vue';
import { useVuelidate } from '@vuelidate/core';
import BnTooltip from '@/components/BnTooltip';

const raceCategoryLevel1Codes = ['1002-5', '2028-9', '2054-5', '2076-8', '2106-3', '2131-1'];
const raceCategoryCodeSystem = 'urn:oid:2.16.840.1.113883.6.238';
const ethnicityCategoryCodeSystem = 'urn:oid:2.16.840.1.113883.6.238';
const raceCategoryData = [
    new FHIRWorksTypes.Coding({
        code: '1002-5',
        system: raceCategoryCodeSystem,
        display: 'American Indian or Alaska Native',
    }),
    new FHIRWorksTypes.Coding({
        code: '2028-9',
        system: raceCategoryCodeSystem,
        display: 'Asian',
    }),
    new FHIRWorksTypes.Coding({
        code: '2054-5',
        system: raceCategoryCodeSystem,
        display: 'Black or African American',
    }),
    new FHIRWorksTypes.Coding({
        code: '2076-8',
        system: raceCategoryCodeSystem,
        display: 'Native Hawaiian or Other Pacific Islander',
    }),
    new FHIRWorksTypes.Coding({
        code: '2106-3',
        system: raceCategoryCodeSystem,
        display: 'White',
    }),
    new FHIRWorksTypes.Coding({
        code: '2131-1',
        system: raceCategoryCodeSystem,
        display: 'Other Race',
    }),
];

const ethnicityCategoryHispanicLatinoCode = '2135-2';
const ethnicityCategoryNotHispanicLatinoCode = '2186-5';
const ethnicityCategoryData = [
    new FHIRWorksTypes.Coding({
        code: ethnicityCategoryHispanicLatinoCode,
        system: ethnicityCategoryCodeSystem,
        display: 'Hispanic or Latino',
    }),
    new FHIRWorksTypes.Coding({
        code: ethnicityCategoryNotHispanicLatinoCode,
        system: ethnicityCategoryCodeSystem,
        display: 'Not Hispanic or Latino',
    }),
];

const livingArrangementData = [
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'CS', display: 'Community shelter' }],
    }),
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'HL', display: 'Homeless' }],
    }),
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'M', display: 'Nomadic' }],
    }),
    new FHIRWorksTypes.CodeableConcept({
        coding: [{ system: 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement', code: 'T', display: 'Transient' }],
    }),
];

const episodeOfCareStatusData = [
    {
        code: 'planned',
        display: 'Planned',
    },
    {
        code: 'waitlist',
        display: 'Waitlist',
    },
    {
        code: 'active',
        display: 'Active',
    },
    {
        code: 'onhold',
        display: 'On hold',
    },
    {
        code: 'finished',
        display: 'Finished',
    },
    {
        code: 'cancelled',
        display: 'Cancelled',
    },
    {
        code: 'entered-in-error',
        display: 'Entered in error',
        disabled: true,
    },
];

export default {
    name: 'BnContactEditHuman',
    components: {
        BnTooltip,
        BnRelated,
        BnInfo,
        BnListIdentifiers,
        BnListQualifications,
        BnContactAddress,
        BnContactPointEdit,
        BnDialog,
        BnListBox,
        BnImageUploader,
        dataProvider,
        BnDatePicker,
    },
    props: {
        resource: Object,
        relationshipProps: Object,
        authStore: Object,
        isWidget: Boolean,
        autosave: Boolean,
        validations: Object,
    },
    setup() {
        const { hasAccessToProgram } = usePatientAccess();
        return { v$: useVuelidate(), hasAccessToProgram };
    },
    emits: ['validate', 'showAccess', 'isPatientOrContact'],
    data() {
        return {
            humanData: null,
            dpLocations: [],
            useValues: traits.contactPointTraits.contactPointUseValues,
            emailUseValues: traits.contactPointTraits.contactEmailUseValues,
            genderOptions: [],
            religiousAffiliationOptions: [],
            pronounOptions: [],
            sexualOrientationOptions: [],
            genderIdentityOptions: [],
            maritalStatusOptions: [],
            raceOptions: [],
            preferredLanguages: [],
            ethnicityDetailedOptions: [],
            sameAsHome: true,
            timezoneData: timezoneApi.loadPickerByRegion(),
            dpData: null,
            qualifications: [],
            raceCategoryOptions: raceCategoryData,
            raceDetailedOptions: [],
            raceDetailedSearch: '',
            raceParentLookup: null,
            ethnicityDetailedSearch: '',
            debounceSearch: null,
            loadingRaceDetailedSearch: false,
            ethnicityCategoryOptions: ethnicityCategoryData,
            phoneUseIcon: {
                mobile: 'mobile',
                home: 'phone-rotary',
                work: 'phone-office',
            },
            phoneUseValues: {
                mobile: 'Mobile',
                home: 'Home',
                work: 'Work',
            },
            emailUseIcon: {
                home: 'at',
                work: 'briefcase',
            },
            emailUseTextValues: {
                home: 'Personal',
                work: 'Work',
            },
            addressUseIcon: {
                home: 'home',
                work: 'building',
                billing: 'mailbox',
            },
            addressUseValues: {
                home: 'Home',
                work: 'Work',
                billing: 'Mailing',
            },
            workingContactPoint: null,
            showContactPointDialog: false,
            workingAddress: null,
            showAddressDialog: false,
            bithDateIsValid: true,
            livingArrangementOptions: livingArrangementData,
            statusCategoryOptions: episodeOfCareStatusData,
        };
    },
    validations() {
        return merge(this.validations, {
            humanData: {
                firstName: {
                    required,
                },
                ssn: {
                    minLength: minLength(11),
                },
                birthDate: {
                    customValidation: () => {
                        return this.isBirthDateValid;
                    },
                },
                temp: {
                    program: {
                        required: requiredIf(() => {
                            return this.isPatient && !this.humanData?.id;
                        }),
                    },
                },
            },
        });
    },
    mounted() {
        if (this.relationshipProps?.scrollToControlRef) {
            this.$nextTick(() => {
                this.$refs[this.relationshipProps.scrollToControlRef].$el.scrollIntoView({ behavior: 'smooth' });
                // setTimeout used to pull the whole field into view
                setTimeout(() => this.$refs[this.relationshipProps.scrollToControlRef].focus(), 150);
            });
        }
    },
    async created() {
        // Load race detailed options so they are displayed
        this.raceDetailedOptions = this.resource.raceDetailed ? this.resource.raceDetailed.map((item) => item) : [];

        // languages,
        pronouns().then((options) => {
            if (options) {
                options = sortByList(
                    options.map((e) => e.resource),
                    ['LA29518-0', 'LA29519-8'],
                    'code',
                    'display',
                );
                this.pronounOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        system: e.system,
                        code: e.code,
                        display: e.display,
                    });
                });
            }
        });

        sexualOrientation().then((options) => {
            if (options) {
                this.sexualOrientationOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        system: e.resource.system,
                        code: e.resource.code,
                        display: e.resource.display,
                    });
                });
            }
        });

        genderIdentity().then((options) => {
            if (options) {
                options = sortByList(
                    options.map((e) => e.resource),
                    ['446151000124109', '446141000124107', 'ASKU'],
                    'code',
                    'display',
                );
                this.genderIdentityOptions = options.map((e) => {
                    return new FHIRWorksTypes.CodeableConcept({
                        title: e.display,
                        coding: [{ system: e.system, code: e.code, display: e.display }],
                    });
                });
            }
        });

        maritalStatus().then((options) => {
            if (options) {
                options = options.map((e) => e.resource).sort((a, b) => (a.display > b.display ? 1 : -1));
                this.maritalStatusOptions = options.map((e) => {
                    return new FHIRWorksTypes.CodeableConcept({
                        title: e.display,
                        coding: [{ system: e.system, code: e.code, display: e.display.toLowerCase().charAt(0).toUpperCase() + e.display.toLowerCase().slice(1) }],
                    });
                });
                // Dedupe options
                this.maritalStatusOptions = [...new Map(this.maritalStatusOptions.map((i) => [i.coding[0].code, i])).values()];
            }
        });

        languages().then((options) => {
            if (options) {
                options = sortByList(
                    options.map((e) => e.resource),
                    ['en', 'es', 'fr', 'de'],
                    'code',
                    'display',
                );
                this.preferredLanguages = options.map((e) => {
                    let languageCodeableConceptJson = {
                        coding: [{ system: 'urn:ietf:bcp:47', code: e.code, display: e.display }],
                        title: e.display,
                    };

                    if (this.resource.resourceType === 'Practitioner') {
                        return new FHIRWorksTypes.CodeableConcept(languageCodeableConceptJson);
                    }
                    // Otherwise this is a Patient
                    return new PatientCommunication({
                        language: languageCodeableConceptJson,
                        preferred: true,
                    });
                });
            }
        });

        religiousAffiliation().then((options) => {
            if (options) {
                this.religiousAffiliationOptions = options.map((e) => {
                    return new FHIRWorksTypes.CodeableConcept({
                        text: e.resource.display,
                        coding: [{ code: e.resource.code, display: e.resource.display }],
                    });
                });
            }
        });

        race().then((options) => {
            if (options) {
                this.raceOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        system: e.resource.system,
                        code: e.resource.code,
                        display: e.resource.display,
                    });
                });
            }
        });

        ethnicityDetail().then((options) => {
            if (options) {
                this.ethnicityDetailedOptions = options.map((e) => {
                    return new FHIRWorksTypes.Coding({
                        code: e.resource.code,
                        system: ethnicityCategoryCodeSystem,
                        display: e.resource.display,
                    });
                });
            }
        });

        administrativeGender().then((options) => {
            // debugger;
            if (options) {
                this.genderOptions = sortByList(
                    options.map((e) => e.resource),
                    ['male', 'female', 'other', 'unknown'],
                    'code',
                );
            }
        });
    },
    computed: {
        showImageUploader() {
            return !this.isWidget;
        },
        isBirthDateValid() {
            return this.bithDateIsValid;
        },
        isPatient() {
            return isPatient(this.resource);
        },
        sortedRaceDetailedOptions() {
            return this.raceDetailedOptions.sort((a, b) => {
                return a.display > b.display ? 1 : -1;
            });
        },
        photo: {
            get: function () {
                return this.humanData?.photo?.[0]?.url;
            },
            set: function (newValue) {
                this.humanData.photo = [new FHIRWorksTypes.Attachment({ url: newValue })];
            },
        },
        dpLocationsQuery() {
            return [
                {
                    query: '_include=organization&_include=location&practitioner=' + this.authStore?.practitioner?.id,
                    resourceType: 'PractitionerRole',
                },
                { query: 'active=true&isProgram=true', resourceType: 'HealthcareService' },
            ];
        },
        locations() {
            let locations = [];
            this.dpLocations
                .filter((item) => item.resourceType === 'Organization')
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                ?.forEach((brand) => {
                    this.dpLocations
                        .filter((item) => item.resourceType === 'Location' && item.managingOrganization?.id === brand.id)
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        ?.forEach((item) => {
                            locations.push({ id: item.id, display: item.name, managingOrganization: { id: brand.id, display: brand.name, resourceType: 'Organization' } });
                        });
                });
            return locations;
        },
        programs() {
            return (
                this.dpLocations
                    .filter((item) => item.resourceType === 'HealthcareService' && item.isProgram && item.location.some((loc) => loc.id === this.humanData.temp.location?.id))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .flatMap((item) => {
                        return { id: item.id, display: item.name, resourceType: item.resourceType };
                    }) || []
            );
        },
        eoc() {
            return this.humanData.temp;
        },
        defaultProgram() {
            return this.dpLocations.find((item) => item.id === this.eoc?.location?.id)?.defaultProgram;
        },
    },
    methods: {
        setBirthDateisValid(isValid) {
            this.bithDateIsValid = isValid;
        },
        handleAddressLatLngChange: function (locationInfo) {
            if (!locationInfo) {
                this.humanData.timeZone = '';
                return;
            }
            getGoogleTimeZoneInfo(locationInfo).then(
                (res) => {
                    this.humanData.timeZone = res.data.timeZoneId;
                },
                () => {},
            );
        },
        programAccess(program, location) {
            const accessStatus = this.hasAccessToProgram(program, location);
            this.$emit('showAccess', accessStatus);
            return accessStatus;
        },
        practitionerLoaded() {
            // load existing qualification
            this.humanData?.qualification.forEach((item) => this.qualifications.push(item.code.coding[0]));
        },
        ethnicityCategoryChangeHandler(ethnicityCategory) {
            if (!(ethnicityCategory instanceof FHIRWorksTypes.Coding)) {
                return;
            }

            if (ethnicityCategory.code === ethnicityCategoryNotHispanicLatinoCode) {
                this.humanData.ethnicityDetailed = undefined;
            }
        },
        ethnicityDetailedChangeHandler(detailItems) {
            this.ethnicityDetailedSearch = '';
            if (!Array.isArray(detailItems) || detailItems.length === 0) {
                return;
            }

            if (!this.humanData.ethnicityCategory || this.humanData.ethnicityCategory.code !== ethnicityCategoryHispanicLatinoCode) {
                this.humanData.ethnicityCategory = ethnicityCategoryData[0];
            }
        },
        async searchRaceDetails(searchVal) {
            // clear results if no searchVal
            if (!searchVal) {
                this.raceDetailedOptions = this.resource.raceDetailed?.map((item) => item) || [];
                return;
            }

            // search
            this.loadingRaceDetailedSearch = true;
            if (!this.raceParentLookup) {
                this.raceParentLookup = new Map();
            }

            let queryString = '/Concept?valueset=v3-Race&hierarchy=' + raceCategoryLevel1Codes.join(',') + '&_sort=display&display=' + searchVal;
            await $httpTerminology.get(queryString).then(async (response) => {
                let results = response.data.entry.map((entryItem) => createFhirResource(entryItem.resource.resourceType, entryItem.resource));

                // reset list to currently selected items
                this.raceDetailedOptions = this.resource.raceDetailed?.map((item) => item) || [];

                // add new search results
                results.forEach((concept) => {
                    if (!this.raceParentLookup.has(concept.code)) {
                        this.raceParentLookup.set(concept.code, concept.hierarchy);
                    }

                    // Check if the race item already exist in the detailed options array
                    if (this.raceDetailedOptions.find((item) => item.code === concept.code)) {
                        return;
                    }
                    this.raceDetailedOptions.push(
                        new FHIRWorksTypes.Coding({
                            code: concept.code,
                            system: raceCategoryCodeSystem,
                            display: concept.display,
                        }),
                    );
                });
            });
            this.loadingRaceDetailedSearch = false;
        },
        raceDetailedChangeHandler(detailItems) {
            if (!this.raceParentLookup) {
                this.raceParentLookup = new Map();
            }
            this.raceDetailedSearch = '';

            detailItems.forEach(async (item) => {
                if (!this.raceParentLookup.has(item.code)) {
                    let queryString = '/Concept?valueset=v3-Race&_count=1&code=' + item.code;
                    await $httpTerminology.get(queryString).then((response) => {
                        let results = response.data.entry.map((entryItem) => createFhirResource(entryItem.resource.resourceType, entryItem.resource));

                        results.forEach((concept) => {
                            this.raceParentLookup.set(concept.code, concept.hierarchy);
                        });
                    });
                }

                let parentTopCode = this.raceParentLookup.get(item.code)[0];
                if (this.humanData.raceCategories.find((item) => item.code === parentTopCode)) {
                    return;
                }

                this.humanData.raceCategories = this.raceCategoryOptions.find((item) => {
                    return item.code === parentTopCode;
                });
            });
        },
        addPhone(phoneOption) {
            // If no phone parameter passed (mouse event found), default to mobile.
            if (phoneOption instanceof Event) phoneOption = 'mobile';

            let disablePreferredCheckBox = false;
            let contactJson = { system: 'phone', use: phoneOption };
            if (!this.humanData.allPhones.length) {
                contactJson.rank = 1;
                disablePreferredCheckBox = true;
            }
            this.workingContactPoint = new ContactPoint(contactJson);
            this.workingContactPoint.temp.mode = 'add';
            this.workingContactPoint.temp.disablePreferred = disablePreferredCheckBox;
            this.showContactPointDialog = true;
        },
        editPhone(phone) {
            this.workingContactPoint = phone;
            this.workingContactPoint.temp.mode = 'edit';
            this.workingContactPoint.temp.disablePreferred = false;
            if (this.workingContactPoint.rank === 1 && this.humanData.allPhones.length > 1) {
                this.workingContactPoint.temp.disablePreferred = true;
            }
            this.showContactPointDialog = true;
        },
        addEmail(emailOption) {
            // If no phone parameter passed (mouse event found), default to mobile.
            if (emailOption instanceof Event) emailOption = 'home';

            let disablePreferredCheckBox = false;
            let emailJson = { system: 'email', use: emailOption };
            if (!this.humanData.allEmails.length) {
                emailJson.rank = 1;
                disablePreferredCheckBox = true;
            }
            this.workingContactPoint = new ContactPoint(emailJson);
            this.workingContactPoint.temp.mode = 'add';
            this.workingContactPoint.temp.disablePreferred = disablePreferredCheckBox;
            this.showContactPointDialog = true;
        },
        editEmail(email) {
            this.workingContactPoint = email;
            this.workingContactPoint.temp.mode = 'edit';
            this.showContactPointDialog = true;
        },
        removeContactPoint(contactPoint) {
            let contactIndex = this.humanData.telecom.indexOf(contactPoint);
            if (contactIndex >= 0) {
                // If we are deleting a mobile phone that is the value for sendSms, clear
                // the resource sendSms value
                if (contactPoint.system === 'phone' && contactPoint.use === 'mobile' && this.humanData.sendSms === contactPoint.value) {
                    this.humanData.sendSms = undefined;
                }
                this.humanData.telecom.splice(contactIndex, 1);
            }
        },
        addAddress(addressOption) {
            // If no phone parameter passed (mouse event found), default to mobile.
            if (addressOption instanceof Event) addressOption = 'home';

            let disablePreferredCheckBox = false;
            this.workingAddress = new Address({ use: addressOption });
            if (!this.humanData.address.length) {
                this.workingAddress.preferred = true;
                disablePreferredCheckBox = true;
            }
            this.workingAddress.temp.mode = 'add';
            this.workingAddress.temp.disablePreferred = disablePreferredCheckBox;
            this.showAddressDialog = true;
        },
        editAddress(address) {
            this.workingAddress = address;
            this.workingAddress.temp.mode = 'edit';
            this.workingAddress.temp.disablePreferred = false;
            if (this.workingAddress.preferred && this.humanData.address.length) {
                this.workingAddress.temp.disablePreferred = true;
            }
            this.showAddressDialog = true;
        },
        removeAddress(addressOption) {
            let addressIndex = this.humanData.address.indexOf(addressOption);
            if (addressIndex >= 0) {
                this.humanData.address.splice(addressIndex, 1);
            }
        },
        sortedAddress(addresses) {
            let addressSortOrder = {
                home: 1,
                mailing: 2,
                billing: 2,
                work: 3,
            };
            return addresses.map((address) => address).sort((a, b) => (a.preferred ? 0 : 1) - (b.preferred ? 0 : 1) || (addressSortOrder[a.use] > addressSortOrder[b.use] ? 1 : -1));
        },

        // Practitioner identifier/qualifications
        setIdentifier(identifier) {
            this.humanData.identifier = identifier;
        },

        resetPronounSelect() {
            this.humanData.pronouns = undefined;
        },

        // EOC
        initEOC() {
            this.setEOCComplaint('Unspecified');
            const defaultLocation = this.locations.find((item) => item.id === this.authStore.brand.location.id);
            this.setEOCLocation(defaultLocation);
            this.setEOCStatus('planned');
        },
        setEOCComplaint(value) {
            this.humanData.temp.complaint = value;
        },
        setEOCLocation(value) {
            this.humanData.temp.location = value;
            const defaultProgram = this.programs.find((item) => item.id === this.defaultProgram?.id) || this.programs[0];
            this.humanData.temp.program = defaultProgram;
        },
        setEOCStatus(value) {
            this.humanData.temp.status = value;
        },
        setEOCEstStartDate(value) {
            this.humanData.temp.eocEstStartDate = value;
        },
        setStatusReason(value) {
            this.humanData.temp.statusReason = value;
        },
        setPlacementStatus(value) {
            this.humanData.temp.placementStatus = value;
        },
        async saveRelationship(newContact) {
            return await this.$refs.contactRelationship.saveNewContactRelationship(newContact);
        },
        closeMenu(e) {
            if (this.$refs[e.toString()]) {
                setTimeout(() => {
                    this.$refs[e.toString()].isMenuActive = false;
                }, 10);
            }
        },
        setBirthDate(date) {
            this.humanData.birthDate = date;
        },
        async validateData() {
            let valid = await this.v$.humanData?.$validate();

            if (!valid) {
                this.v$.humanData.$touch();
            }
            this.$emit('isPatientOrContact', this.isPatient);
            this.$emit('validate', !valid);
        },
    },
    watch: {
        bithDateIsValid: {
            handler() {
                this.validateData();
            },
            immediate: true,
        },
        humanData: {
            async handler() {
                this.validateData();
            },
            immediate: true,
            deep: true,
        },
        raceDetailedSearch(value) {
            if (this.debounceSearch) {
                this.debounceSearch(value);
                return;
            }
            this.debounceSearch = debounce(this.searchRaceDetails, 500);
        },
    },
};
</script>

<template>
    <data-provider v-model="humanData" :autosave="autosave" :resource="resource" :validator="v$.humanData">
        <template #default>
            <template v-if="$vuetify.display.xs">
                <v-row>
                    <v-col v-if="showImageUploader">
                        <bn-image-uploader
                            ref="humanData"
                            :resource="humanData"
                            :options="{ size: 48 }"
                            :alt="humanData.resourceType + ' image'"
                            v-model="photo"
                            :contact-edit="true"
                            :avatar="true"
                            :vocab="authStore.clientVocab"
                            :is-public="humanData.resourceType !== 'Patient'"
                            placeholder
                            class="d-flex py-0 justify-center"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.firstName"
                            label="First name"
                            autofocus
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'firstName', true)"
                            @blur="v$.humanData.firstName.$touch()"
                            data-cy="contactEditHumanFirstName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.lastName"
                            label="Last name"
                            data-cy="contactEditHumanLastName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'lastName', true)"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field v-model="humanData.middleName" label="Middle name" data-cy="contactEditHumanMiddleName" :class="{ usprivacy: getUSPrivacy(resource) }"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field v-model="humanData.preferredName" label="Preferred name" data-cy="contactEditHumanPreferredName" :class="{ usprivacy: getUSPrivacy(resource) }">
                            <template #append>
                                <bn-info type="dialog" size="lg"> The individual's preferred name. If specified the preferred name will display in quotes after their first name. </bn-info>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.givenPhoneticSpelling"
                            label="First name phonetic spelling"
                            data-cy="contactEditHumanGivenPhoneticSpelling"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        >
                            <template #append>
                                <bn-info type="dialog" size="lg">
                                    Spelling of the individuals name the way it sounds. Phonetic spelling will display in parentheses after their first name IF preferred name is not specified.
                                </bn-info>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Maiden or prior name-->
                    <v-col class="d-flex py-0">
                        <v-text-field v-model="humanData.maidenName" label="Maiden or prior name" :class="{ usprivacy: getUSPrivacy(resource) }"> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <bn-date-picker
                            dob
                            v-model="humanData.birthDate"
                            :input-date-format="authStore.inputDateFormat"
                            label="Date of birth"
                            id="contactEditHumanDateOfBirth"
                            :passed-class="getUSPrivacy(resource) ? 'usprivacy' : ''"
                            @update:modelValue="setBirthDate"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'birthDate', true)"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <v-col class="d-flex pb-0">
                        <v-text-field
                            v-model="humanData.firstName"
                            label="First name"
                            autofocus
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'firstName', true)"
                            @blur="v$.humanData.firstName.$touch()"
                            data-cy="contactEditHumanFirstName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col v-if="showImageUploader">
                        <bn-image-uploader
                            ref="humanData"
                            :resource="humanData"
                            :options="{ size: 48 }"
                            :alt="humanData.resourceType + ' image'"
                            v-model="photo"
                            :contact-edit="true"
                            :avatar="true"
                            :vocab="authStore.clientVocab"
                            :is-public="humanData.resourceType !== 'Patient'"
                            placeholder
                            class="align-self-center py-0"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.lastName"
                            label="Last name"
                            data-cy="contactEditHumanLastName"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'lastName', true)"
                        ></v-text-field>
                    </v-col>
                    <v-col class="d-flex py-0">
                        <v-text-field v-model="humanData.middleName" label="Middle name" data-cy="contactEditHumanMiddleName" :class="{ usprivacy: getUSPrivacy(resource) }"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="d-flex py-0">
                        <v-text-field v-model="humanData.preferredName" label="Preferred name" data-cy="contactEditHumanPreferredName" :class="{ usprivacy: getUSPrivacy(resource) }">
                            <template #append>
                                <bn-info type="dialog" size="lg"> The individual's preferred name. If specified the preferred name will display in quotes after their first name. </bn-info>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="d-flex py-0">
                        <v-text-field
                            v-model="humanData.givenPhoneticSpelling"
                            label="First name phonetic spelling"
                            data-cy="contactEditHumanGivenPhoneticSpelling"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        >
                            <template #append>
                                <bn-info type="dialog" size="lg">
                                    Spelling of the individuals name the way it sounds. Phonetic spelling will display in parentheses after their first name IF preferred name is not specified.
                                </bn-info>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="pt-0">
                        <bn-date-picker
                            dob
                            v-model="humanData.birthDate"
                            :input-date-format="authStore.inputDateFormat"
                            label="Date of birth"
                            id="contactEditHumanDateOfBirth"
                            :passed-class="getUSPrivacy(resource) ? 'usprivacy' : ''"
                            @update:modelValue="setBirthDate"
                            @isDateValid="setBirthDateisValid"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'birthDate', true)"
                            :class="{ usprivacy: getUSPrivacy(resource) }"
                        />
                    </v-col>
                    <!-- Maiden or prior name-->
                    <v-col v-if="isPatient" cols="6" class="py-0">
                        <v-text-field v-model="humanData.maidenName" label="Maiden or prior name" :class="{ usprivacy: getUSPrivacy(resource) }"> </v-text-field>
                    </v-col>
                </v-row>
            </template>

            <bn-related
                v-if="relationshipProps && relationshipProps.relationSubject && relationshipProps.relationSubject.id"
                ref="contactRelationship"
                :subject-id="relationshipProps.relationSubject.id"
                :subject-resource-type="relationshipProps.relationSubject.resourceType"
                :adding-new-contact="true"
                :new-contact-data="humanData"
                :is-emergency-contact="relationshipProps.isEmergencyContact"
                :is-legal-guardian="relationshipProps.isLegalGuardian"
            ></bn-related>

            <!-- New Patient Episode of Care details -->
            <template v-if="isPatient && !humanData.id">
                <div class="bn-section-header">Initial Episode of Care</div>
                <br />
                <v-row>
                    <v-col class="d-flex py-0">
                        <!-- Reason for Seeking Care -->
                        <v-text-field v-model="eoc.complaint" label="Reason for seeking care" @update:modelValue="setEOCComplaint" data-cy="initialEocComplaint"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <!--Status-->
                        <v-autocomplete v-model="eoc.status" label="Episode status" :items="statusCategoryOptions" item-title="display" item-value="code" auto-select-first>
                            <template #item="{ item, props }">
                                <v-list-item v-bind="props" :disabled="item.raw.disabled" />
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <div class="v-label ml-1">Placement status</div>
                        <v-radio-group v-model="eoc.placementStatus" @update:model-value="setPlacementStatus" inline>
                            <v-radio value="Unknown" label="Unknown" class="mr-2" />
                            <v-radio value="Qualified" label="Qualified" class="mr-2" />
                            <v-radio value="Not qualified" label="Not qualified" />
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-text-field label="Status reason" v-model="eoc.statusReason" @update:model-value="setStatusReason" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex py-0">
                        <!-- Location -->
                        <data-provider v-model="dpLocations" collection :query="dpLocationsQuery" @loaded="initEOC" />
                        <v-autocomplete v-model="eoc.location" label="Location" :items="locations" item-title="display" return-object @update:modelValue="setEOCLocation" data-cy="patientLocation">
                            <template #item="{ item, props }">
                                <v-list-item v-bind="props" :title="null">
                                    <font-awesome-icon :icon="['far', 'map-marker-alt']" class="mr-4" />
                                    {{ item.title }}
                                </v-list-item>
                            </template>
                            <template #selection="{ item }">
                                <font-awesome-icon :icon="['far', 'map-marker-alt']" class="mr-4" />
                                {{ item.title }}
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <v-autocomplete
                            v-model="eoc.program"
                            label="Program"
                            :items="programs"
                            item-title="display"
                            return-object
                            data-cy="patientProgram"
                            v-bind="$bnVuelidateErrorExtractor(v$.humanData.temp, 'program', true)"
                            @blur="v$.humanData.temp.program.$touch()"
                        >
                            <template #item="{ item, props }">
                                <v-list-item v-bind="props" :title="null">
                                    <font-awesome-icon :icon="['far', 'route']" class="mr-4" />
                                    {{ item.title }}
                                </v-list-item>
                            </template>
                            <template #selection="{ item }">
                                <font-awesome-icon :icon="['far', 'route']" class="mr-4" />
                                {{ item.title }}
                            </template>
                            <template v-if="!programAccess(eoc.program, eoc.location) && eoc.program !== null && eoc.location !== null" #prepend>
                                <bn-tooltip type="info">
                                    <v-btn icon variant="text" size="small" color="warning">
                                        <font-awesome-icon :icon="['far', 'triangle-exclamation']" size="lg" />
                                    </v-btn>
                                    <template #message>
                                        <span
                                            >You do not have access to {{ authStore.clientVocab.toLowerCase() }}s in this program at this location and will not be able to access this
                                            {{ authStore.clientVocab.toLowerCase() }} once they are added.</span
                                        >
                                    </template>
                                </bn-tooltip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <!--Estimated Start Date-->
                    <v-col class="py-0">
                        <bn-date-picker
                            v-model="eoc.eocEstStartDate"
                            :input-date-format="authStore.inputDateFormat"
                            label="Estimated start date"
                            id="eocEstStartDate"
                            @update:modelValue="setEOCEstStartDate"
                        />
                    </v-col>
                </v-row>
            </template>

            <bn-list-box title="Phone" add @add="addPhone" data-cy="contactEditHumanPhoneList">
                <template #default>
                    <v-card v-if="!humanData.allPhones.length" flat class="d-flex text-caption pa-2" @click="addPhone" :border="false">
                        <div class="ml-auto">
                            Add phone
                            <font-awesome-icon :icon="['far', 'level-up']" class="ml-1 mr-3 mb-1" />
                        </div>
                    </v-card>
                    <template v-else v-for="(phone, index) in humanData.allPhones.sort((a, b) => ((a.rank || 0) > (b.rank || 0) ? -1 : 1))" :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-list-item density="compact" class="pr-0" @click="editPhone(phone)">
                            <template #prepend>
                                <template v-if="phone.rank === 1">
                                    <font-awesome-icon :icon="['fas', 'star']" class="text-primary mr-2 align-self-center" />
                                </template>
                                <template v-else>
                                    <font-awesome-icon :icon="['far', phoneUseIcon[phone.use]]" class="mr-2 align-self-center" />
                                </template>
                            </template>
                            <v-list-item-subtitle>
                                <div class="bn-label">
                                    {{ phoneUseValues[phone.use] }}
                                </div>
                            </v-list-item-subtitle>
                            <v-list-item-title :class="{ usprivacy: getUSPrivacy(resource) }">{{ phone.value }}</v-list-item-title>
                            <div>
                                <v-chip v-if="phone.doNotContact" variant="outlined" size="x-small">
                                    <font-awesome-icon :icon="['far', 'ban']" />
                                    &nbsp; Do Not Contact
                                </v-chip>
                                <v-chip v-if="humanData.sendSms === phone.value" variant="outlined" size="x-small">
                                    <font-awesome-icon :icon="['far', 'comment-alt']" />
                                    &nbsp; Accepts SMS
                                </v-chip>
                            </div>
                            <template #append>
                                <bn-tooltip v-if="phone.rank === 1 && humanData.allPhones.length > 1" bottom type="info">
                                    <v-btn class="d-flex align-center mr-2" icon variant="text" size="small" color="red" disabled>
                                        <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                    </v-btn>
                                    <template #message>
                                        <span>A preferred phone number must be designated.</span>
                                    </template>
                                </bn-tooltip>
                                <v-btn v-else class="d-flex align-center mr-2" icon variant="text" size="small" color="red" @click.stop="removeContactPoint(phone)">
                                    <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                </v-btn>
                            </template>
                        </v-list-item>
                    </template>
                </template>
            </bn-list-box>

            <bn-list-box title="Email" add @add="addEmail" data-cy="contactEditHumanEmailList">
                <template #default>
                    <v-card v-if="!humanData.allEmails.length" flat class="d-flex text-caption pa-2" @click="addEmail" :border="false">
                        <div class="ml-auto">
                            Add email
                            <font-awesome-icon :icon="['far', 'level-up']" class="mx-1 mb-1" />
                        </div>
                    </v-card>
                    <template v-else v-for="(email, index) in humanData.allEmails.sort((a, b) => ((a.rank || 0) > (b.rank || 0) ? -1 : 1))" :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-list-item density="compact" class="pr-0" @click="editEmail(email)">
                            <template #prepend>
                                <div v-if="email.rank === 1" class="mr-2 align-self-center">
                                    <font-awesome-icon :icon="['fas', 'star']" class="text-primary" />
                                </div>
                                <div v-else class="mr-2 align-self-center">
                                    <font-awesome-icon v-if="email.use === 'work'" :icon="['far', emailUseIcon[email.use]]" />
                                    <font-awesome-icon v-else :icon="['far', 'at']" />
                                </div>
                            </template>
                            <v-list-item-subtitle>
                                <div class="bn-label">
                                    {{ emailUseTextValues[email.use] }}
                                </div>
                            </v-list-item-subtitle>
                            <v-list-item-title :class="{ usprivacy: getUSPrivacy(resource) }">{{ email.value }}</v-list-item-title>
                            <div>
                                <v-chip v-if="email.doNotContact" label text x-small>
                                    <font-awesome-icon :icon="['far', 'ban']" />
                                    &nbsp; Do Not Contact
                                </v-chip>
                            </div>
                            <template #append>
                                <v-btn class="d-flex mr-2" icon variant="text" size="small" color="red" @click.stop="removeContactPoint(email)">
                                    <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                </v-btn>
                            </template>
                        </v-list-item>
                    </template>
                </template>
            </bn-list-box>

            <bn-list-box title="Address" add="Add Address" data-cy="contactEditHumanAddressList" @add="addAddress" :required="v$.humanData.address?.required && !humanData.address.length">
                <template #default>
                    <v-card v-if="!humanData.address.length" flat class="d-flex text-caption pa-2" @click="addAddress" :border="false">
                        <div class="ml-auto">
                            Add address
                            <font-awesome-icon :icon="['far', 'level-up']" class="ml-1 mr-3 mb-1" />
                        </div>
                    </v-card>
                    <template v-else v-for="(addressItem, index) in sortedAddress(humanData.address)" :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-list-item density="compact" class="pr-0" @click="editAddress(addressItem)">
                            <template #prepend>
                                <div v-if="addressItem.preferred" class="mr-2 align-self-center">
                                    <font-awesome-icon :icon="['fas', 'star']" class="text-primary" />
                                </div>
                                <div v-else class="mr-2 align-self-center">
                                    <font-awesome-icon v-if="addressItem.use" :icon="['far', addressUseIcon[addressItem.use]]" />
                                </div>
                            </template>
                            <v-list-item-subtitle class="bn-label">{{ addressUseValues[addressItem.use] }}</v-list-item-subtitle>
                            <v-list-item-title :class="{ usprivacy: getUSPrivacy(resource) }"
                                >{{ addressItem.getAddressLine(0) }}<br />{{ addressItem.city + ' ' + addressItem.state + ' ' + addressItem.postalCode }} <br />
                                {{ addressItem.district ? addressItem.district : '' }}</v-list-item-title
                            >
                            <template #append>
                                <bn-tooltip v-if="addressItem.preferred && humanData.address.length > 1" bottom type="info">
                                    <v-btn class="d-flex align-center mr-2" icon variant="text" size="small" color="red" disabled>
                                        <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                    </v-btn>
                                    <template #message>
                                        <span>A preferred address must be designated.</span>
                                    </template>
                                </bn-tooltip>
                                <v-btn v-else class="d-flex align-center mr-2" icon variant="text" size="small" color="red" @click.stop="removeAddress(addressItem)">
                                    <font-awesome-icon :icon="['far', 'minus-circle']" size="lg" />
                                </v-btn>
                            </template>
                        </v-list-item>
                    </template>
                </template>
            </bn-list-box>

            <v-autocomplete
                v-if="isPatient"
                label="Lacking permanent address"
                v-model="humanData.livingArrangement"
                :items="livingArrangementOptions"
                return-object
                item-title="coding[0].display"
                @click:clear="humanData.livingArrangement = undefined"
                clearable
                placeholder="No"
                persistent-placeholder
            >
                <template #no-data>
                    <v-list-item>
                        <v-list-item-title> No option found </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <v-autocomplete label="Timezone" v-model="humanData.timeZone" :items="timezoneData" item-title="timezone" item-value="timezone" clearable placeholder="Select timezone">
                <template #no-data>
                    <v-list-item>
                        <v-list-item-title> Timezone not found </v-list-item-title>
                    </v-list-item>
                </template>
                <template #item="{ item, props }">
                    <v-list-subheader v-if="item.raw.header">
                        {{ item.raw.header }}
                    </v-list-subheader>
                    <v-divider v-else-if="item.raw.divider" />
                    <v-list-item v-else v-bind="props" />
                </template>
            </v-autocomplete>

            <template v-if="humanData.resourceType === 'Practitioner'">
                <!-- User Qualifications -->
                <bn-list-qualifications v-model="humanData.qualification" @update:modelValue="humanData.qualification = $event" />
                <!-- User Identifiers -->
                <bn-list-identifiers title="Practitioner identifiers" type="Practitioner" v-model="humanData.identifier" />
            </template>

            <div class="bn-section-header">Gender</div>
            <div class="d-flex">
                <v-select v-model="humanData.gender" label="Sex" :items="genderOptions" item-title="display" item-value="code">
                    <template #append>
                        <bn-info type="dialog" size="lg"> Current biological and physiological sex. </bn-info>
                    </template>
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title> Sex not found </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-select>
            </div>
            <div class="d-flex">
                <v-select
                    v-if="humanData.resourceType === 'Patient'"
                    label="Gender identity"
                    v-model="humanData.genderIdentity"
                    :items="genderIdentityOptions"
                    item-title="coding[0].display"
                    return-object
                >
                    <template #append>
                        <bn-info size="lg" type="dialog"> The personal sense of being a man, woman, or other gender, regardless of the sex that person was assigned at birth. </bn-info>
                    </template>
                </v-select>
            </div>
            <v-select label="Pronouns" v-model="humanData.pronouns" :items="pronounOptions" item-title="display" return-object clearable @click:clear="resetPronounSelect"></v-select>
            <!-- <v-select label="Sexual Orientation" v-model="humanData.sexualOrientation" :items="sexualOrientationOptions" item-text="display" return-object clearable></v-select>-->

            <template v-if="humanData.resourceType === 'Patient'">
                <div class="bn-section-header">Race & Ethnicity</div>
                <v-select
                    ref="raceSelect"
                    label="Race"
                    v-model="humanData.raceCategories"
                    :items="raceCategoryOptions"
                    item-title="display"
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    data-cy="raceCategories"
                >
                    <template #prepend-item>
                        <div class="pa-2 text-caption d-flex justify-space-between">
                            Press the ESC key to close this list
                            <button @click="closeMenu('raceSelect')" class="mr-2">
                                <font-awesome-icon size="lg" :icon="['far', 'times']" class="mr-2" />
                            </button>
                        </div>
                    </template>
                </v-select>
                <v-autocomplete
                    v-if="!isWidget"
                    label="Race detailed"
                    ref="raceDetailedSelect"
                    v-model="humanData.raceDetailed"
                    :loading="loadingRaceDetailedSearch"
                    v-model:search="raceDetailedSearch"
                    :items="sortedRaceDetailedOptions"
                    item-title="display"
                    searchable
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    @update:modelValue="raceDetailedChangeHandler"
                    data-cy="raceDetailed"
                    clearable
                >
                    <template #prepend-item>
                        <div class="pa-2 text-caption d-flex justify-space-between">
                            Press the ESC key to close this list
                            <button @click="closeMenu('raceDetailedSelect')" class="mr-2">
                                <font-awesome-icon size="lg" :icon="['far', 'times']" class="mr-2" />
                            </button>
                        </div>
                    </template>
                    <template #no-data>
                        <div class="pa-4 body-2">
                            {{ loadingRaceDetailedSearch ? 'Searching...' : 'Search for detailed race' }}
                        </div>
                    </template>
                </v-autocomplete>
                <v-autocomplete
                    label="Ethnicity"
                    v-model="humanData.ethnicityCategory"
                    :items="ethnicityCategoryOptions"
                    item-title="display"
                    searchable
                    return-object
                    small-chips
                    deletable-chips
                    @update:update:modelValue="ethnicityCategoryChangeHandler"
                    data-cy="ethnicityCategory"
                >
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title> Ethnicity not found </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <v-autocomplete
                    v-if="!isWidget"
                    ref="ethinicityDetailedSelect"
                    label="Ethnicity detailed"
                    v-model="humanData.ethnicityDetailed"
                    v-model:search-input="ethnicityDetailedSearch"
                    :items="ethnicityDetailedOptions"
                    item-title="display"
                    searchable
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    @update:modelValue="ethnicityDetailedChangeHandler"
                    data-cy="ethnicityDetailed"
                >
                    <template #prepend-item>
                        <div class="pa-2 text-caption d-flex justify-space-between">
                            Press the ESC key to close this list
                            <button @click="closeMenu('ethinicityDetailedSelect')" class="mr-2">
                                <font-awesome-icon size="lg" :icon="['far', 'times']" class="mr-2" />
                            </button>
                        </div>
                    </template>
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title> Ethnicity Details not found </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </template>

            <div class="bn-section-header">Additional demographics</div>
            <v-select
                v-if="humanData.resourceType === 'Patient'"
                v-model="humanData.maritalStatus"
                label="Marital status"
                :items="maritalStatusOptions"
                item-title="coding[0].display"
                return-object
                clearable
                @click:clear="humanData.maritalStatus = undefined"
            ></v-select>

            <v-select
                v-if="resource.resourceType === 'Practitioner'"
                label="Preferred language"
                v-model="humanData.spokenLanguage"
                :items="preferredLanguages"
                item-title="coding[0].display"
                return-object
                clearable
            ></v-select>
            <v-select
                v-else
                label="Preferred language"
                v-model="humanData.spokenLanguage"
                :items="preferredLanguages"
                item-title="language.coding[0].display"
                return-object
                clearable
                @click:clear="humanData.spokenLanguage = undefined"
            ></v-select>
            <v-text-field
                v-if="humanData.resourceType === 'Patient'"
                label="Social security number"
                v-model="humanData.ssn"
                v-facade="'###-##-####'"
                v-bind="$bnVuelidateErrorExtractor(v$.humanData, 'ssn', true)"
                @blur="v$.humanData.ssn.$touch()"
                :class="{ usprivacy: getUSPrivacy(resource) }"
            >
                <template #append>
                    <bn-info type="dialog" color="warning" size="lg">
                        Only collect a Social Security Number (SSN) if absolutely necessary. Many payers/providers actively purge SSNs from their systems and/or filter them from incoming data.
                    </bn-info>
                </template>
            </v-text-field>
            <v-text-field v-if="humanData.resourceType === 'Patient'" label="Driver license number" v-model="humanData.driversLicense" class="usprivacy"></v-text-field>
            <v-select
                label="Religion"
                v-model="humanData.religion"
                :items="religiousAffiliationOptions"
                item-title="coding[0].display"
                return-object
                clearable
                @click:clear="humanData.religion = undefined"
            ></v-select>
            <v-text-field v-if="isPatient" label="Employer and occupation or school" v-model="humanData.employerSchool"></v-text-field>
            <div>
                <v-textarea v-if="!isWidget" v-model="humanData.keyComments" label="Helpful comments" ref="keyComments" filled auto-grow rows="1" data-cy="keyCommentsTextarea" class="usprivacy">
                    <template #append>
                        <bn-info v-if="isPatient" type="dialog" size="lg">
                            Use helpful comments to note any important, non-clinical, details to better serve this {{ authStore.clientVocab.toLowerCase() }}.
                        </bn-info>
                    </template>
                </v-textarea>
            </div>
            <bn-dialog ref="bnDialog" />

            <v-dialog v-if="showContactPointDialog" v-model="showContactPointDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="450px">
                <bn-contact-point-edit
                    id="bn-contact-point-edit"
                    :mode="workingContactPoint.temp.mode"
                    :disable-preferred-check="workingContactPoint.temp.disablePreferred"
                    :resource="humanData"
                    :contact-point="workingContactPoint"
                    @cancel="showContactPointDialog = false"
                    @save="showContactPointDialog = false"
                ></bn-contact-point-edit>
            </v-dialog>

            <v-dialog v-if="showAddressDialog" v-model="showAddressDialog" scrollable persistent :fullscreen="$vuetify.display.xs" max-width="450px">
                <bn-contact-address
                    id="bn-contact-address"
                    :mode="workingAddress.temp.mode"
                    :disable-preferred-check="workingAddress.temp.disablePreferred"
                    :resource="humanData"
                    :address="workingAddress"
                    @cancel="showAddressDialog = false"
                    @save="showAddressDialog = false"
                ></bn-contact-address>
            </v-dialog>
        </template>
    </data-provider>
</template>

<style>
.v-card.v-theme--lightTheme.v-card--density-compact.v-card--variant-flat.no-select .d-flex .bn-section-header {
    margin-top: 8px;
}
.v-card.v-theme--lightTheme.v-card--density-compact.v-card--variant-flat.no-select .d-flex .bn-section-header .ml-4.d-flex {
    margin-bottom: -4px;
}
</style>
